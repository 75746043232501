@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap');

abbr[title] {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}

.hideMenuNav {
  display: none;
}
.showMenuNav {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

html {
  font-family: 'Roboto', sans-serif;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-y: overlay;
}

::-webkit-scrollbar {
  background: #979797;
}

::-webkit-scrollbar-thumb {
  background: #dadada;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: #e9e9e9;
}

#blinkingicon {
  background: rgba(29, 167, 29, 0.9); 
  box-shadow: 0 0 0.5em 0.15em rgba(29, 167, 29, 0.9); 
  border: 1px solid rgba(0,0,0,0.25);
  transform: scale(1);
	animation: pulse 3s infinite;
}
@keyframes pulse {
	0% {
		transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(29, 167, 29, 0.3);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.85);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

#blinkingiconEnded {
  background: rgba(255, 47, 32, 0.9); 
  box-shadow: 0 0 0.5em 0.15em rgba(255, 49, 35, 0.9); 
  border: 1px solid rgba(0,0,0,0.25);
  transform: scale(1);
	animation: pulseRed 3s infinite;
}
@keyframes pulseRed {
	0% {
		transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(255, 49, 35, 0.3);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.85);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

#blinkingiconNotStarted {
  background: rgba(138, 138, 138, 0.9); 
  box-shadow: 0 0 0.5em 0.15em rgba(138, 138, 138, 0.9); 
  border: 1px solid rgba(0,0,0,0.25);
  transform: scale(1);
	animation: pulseGray 3s infinite;
}
@keyframes pulseGray {
	0% {
		transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(138, 138, 138, 0.3);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.85);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.modal {
  display: none;
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.5);
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  padding: 20px;
  border: 1px solid #888;
  max-height: 92vh;
  color: #000032;
}
.modal-content > div {
  display: flex;
  margin-left: auto;
  margin-right: auto;
} 
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}





